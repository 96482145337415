import React from 'react';

export const Logo = ({ isWhite }) => {
  return (
    <img
      src="https://esparta.s3.amazonaws.com/NS_Maior_final.png"
      alt="NS Logo"
      style={
        isWhite
          ? { maxHeight: '55px' }
          : { maxHeight: '55px', filter: 'invert(48%) sepia(76%) saturate(3336%) hue-rotate(359deg) brightness(100%) contrast(108%)' }
      }
    ></img>
  );
};
