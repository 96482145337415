import { Info, StyledInfoList, StyledText } from '../style';

import TextProcessing from 'components/text-processing';
import { styledText } from 'components/text-processing/style-constants';
import { TextVariants } from 'model/enums/text-variants';
import { InfinityDataScreenType } from 'model/landing-page';

import { InfinityScreenImage } from 'images/infinity-screen';
import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

interface InfoListProps {
  data?: InfinityDataScreenType;
}

const InfoList: React.FC<InfoListProps> = ({ data }) => {
  const theme = useTheme();

  const iconList: ReactElement[] = [
    <InfinityScreenImage.dollar />,
    <InfinityScreenImage.calendar />,
    <InfinityScreenImage.clock />,
    <InfinityScreenImage.lightning />,
    <InfinityScreenImage.lightning />,
  ];

  return (
    <StyledInfoList id={data?.sectionId}>
      {data?.options.map((info, idx) => (
        <Info key={idx}>
          {iconList[idx]}
          <StyledText>
            <TextProcessing
              text={info}
              customStyle={{
                default: {
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: theme.color.lpColors.infinityDataText,
                },
              }}
            />
          </StyledText>
        </Info>
      ))}
    </StyledInfoList>
  );
};

export default InfoList;
