import TextProcessing from 'components/text-processing';
import { styledTitle } from 'components/text-processing/style-constants';
import { Logo } from 'images/custom-organization';
import { FooterImgs } from 'images/footer';
import { SectionName } from 'model/enums/section-name';
import { TextVariants } from 'model/enums/text-variants';
import { FooterScreenType } from 'model/landing-page';
import { useTranslation } from 'react-i18next';
import { goTo } from 'shared/util/go-to';
import { useTheme } from 'styled-components';
import {
  CopyrightBox,
  FooterContainer,
  FooterContent,
  LinksBox,
  LinksItemBox,
  LogoBox,
  SectionLinks,
  StyledImage,
  StyledLink,
  StyledTitle,
} from '../styled';

interface DesktopProps {
  data?: FooterScreenType;
}

const Desktop: React.FC<DesktopProps> = ({ data }) => {
  const theme = useTheme();
  const socialMedia = Object.keys(FooterImgs);
  const { t } = useTranslation();
  return (
    <>
      <FooterContainer>
        <FooterContent>
          <LogoBox>
            <Logo isWhite={true} />
          </LogoBox>
          <LinksBox>
            {data?.sections.map((section, idx) => (
              <SectionLinks key={idx}>
                <StyledTitle>
                  <TextProcessing text={section?.name} customStyle={styledTitle(TextVariants.QUINARY, theme)} />
                </StyledTitle>
                <LinksItemBox $customStyle={{ isActive: section.name.includes(SectionName.FOLLOWCOMPANY) }}>
                  {section.name === SectionName.ABOUT
                    ? section.data.map((data, idx1) => (
                        <StyledLink key={idx1} onClick={() => goTo(data.sectionId)}>
                          {socialMedia.includes(data.name) ? <StyledImage src={FooterImgs[data.name]} /> : data.name}
                        </StyledLink>
                      ))
                    : section.data.map((data, idx1) => (
                        <StyledLink key={idx1} onClick={console.log}>
                          {socialMedia.includes(data.name) ? <StyledImage src={FooterImgs[data.name]} /> : data.name}
                        </StyledLink>
                      ))}
                </LinksItemBox>
              </SectionLinks>
            ))}
          </LinksBox>
        </FooterContent>
      </FooterContainer>
      {/* i18n */}
      <CopyrightBox>{t('footer.creditCopy')}</CopyrightBox>
    </>
  );
};

export default Desktop;
