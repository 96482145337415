import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import { INTL_CURRENCY, INTL_LOCALE, MOMENT_LOCALE } from '../../config/constants';

moment.locale(MOMENT_LOCALE);

const CURRENCY_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
  style: 'currency',
  currency: INTL_CURRENCY,
  minimumFractionDigits: 2,
});

const PERCENTUAL_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const DECIMAL_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const formatCurrency = (value?: number) => CURRENCY_FORMATTER.format(value ?? 0);
const formatPercent = (value?: number) => PERCENTUAL_FORMATTER.format(value ?? 0);
const formatDecimal = (value?: number) => DECIMAL_FORMATTER.format(value ?? 0);

const formatDate = (date?: Date): string => {
  if (!date) return '';

  return moment(date).format('L');
};

export const FormatUtils = {
  formatCurrency,
  formatPercent,
  formatDate,
  formatDecimal,
};

export default FormatUtils;
