import { WhatsIsImage } from 'images/what-is-screen';
import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

export const ScreenContent = styled.div`
  max-width: 1440px;
  width: 100%;
  min-width: 480px;

  padding: 72px 128px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 1;

  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    padding: 72px 96px;
    flex-direction: column;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    padding: 36px 48px;
    flex-direction: column;
  }
  @media (max-width: ${ScreenDimensions.MOBILE}) {
    padding: 24px 16px;

    max-width: 480px;
    width: 100%;
    min-width: 280px;
  }
`;

export const ShadowElipse1 = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;

  background-repeat: no-repeat;
  background-position: top right;
  position: absolute;
`;

export const ShadowElipse2 = styled(ShadowElipse1)`
  background-image: url(${WhatsIsImage.shadowElipse2});
  background-position: top left;
`;

export const StyledImage = styled.img`
  max-width: 576px;
  width: auto;
  height: auto;
  margin: 0px 24px 0px 0px;
  object-fit: contain;

  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    margin: 0px 0px 0px 0px;
  }
  @media only screen and (max-width: 675px) {
    width: 100%;
    margin-top: 24px;
  }
`;

export const InfoContent = styled.div`
  max-width: 400px;
  width: 100%;
  min-width: 400px;

  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    max-width: 480px;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    min-width: 272px;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${({ theme }) => theme.color.lpColors.whatIsScreenTextColor};
`;

export const StyledDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.lpColors.whatIsScreenTextColor};

  margin: 24px 0px 0px;
  text-align: left;
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px;
`;

export const StyledIcon = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin: 0px 4px 0px 0px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px 32px;
`;

export const StyledText = styled.div`
  max-width: 100%;
  text-align: left;
  margin: 0px 0px 0px 12px;
`;

export const StyledGoToSimulatorButton = styled.div`
  border-radius: 24px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  margin-top: 32px;
  color: #fff;
  ${({ theme }) => {
    return `
          background: ${theme.color.lpColors.simulatorButton}; `;
  }}
`;

export const StyledNewItem = styled.div`
  display: flex;
  flex-direction: column;

  margin: 28px 0px 0px;
`;

export const NewStyledTitle = styled.div`
  max-width: 100%;
  text-align: left;
  margin: 8px 0px 0px 12px;
  color: var(--gray-dark-1, #1b1a1b);
  ${({ theme }) => {
    return `
          color: ${theme.color.lpColors.whatIsScreenTextColor}; `;
  }}
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

export const NewStyledText = styled.div`
  max-width: 100%;
  text-align: left;
  margin: 0px 0px 0px 12px;
  ${({ theme }) => {
    return `
          color: ${theme.color.lpColors.whatIsScreenTextColor}; `;
  }}
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const StyledDesktopNewItem = styled.div`
  display: flex;

  margin: 28px 0px 0px;
`;

export const IconContainer = styled.div`
  min-width: 40px;
  margin-right: 16px;
  padding-top: 8px;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${({ theme }) => theme.color.lpColors.whatIsIcon};
    }
  }
`;
