import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'components/text-processing/style-constants';
import { GeneralImages } from 'images/general-images';
import { TextVariants } from 'model/enums/text-variants';
import { OpeningScreenType, TaxesAndConditionsScreenType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import Simulator from '../simulator';
import { ImageBox, ScreenContent, StyledImage, StyledText, TextContainer } from '../style';
import {
  BigNumber,
  BlackTitle,
  CardBlack,
  CardRed,
  InnerCard,
  InnerTable,
  RedTitle,
  ShadowElipse2,
  SpecialSpan,
  TablesContainer,
} from 'components/landing-page/taxes-and-conditions-screen/style';
import { Trans } from 'react-i18next';
import FormatUtils from 'shared/util/format-utils';
import { ReactComponent as WhatsIconRed } from '../../../../images/general-images/WhatsappRed.svg';

interface DesktopProps {
  data?: OpeningScreenType;
  taxesAndConditionsData?: TaxesAndConditionsScreenType;
}

const Desktop: React.FC<DesktopProps> = ({ data, taxesAndConditionsData }) => {
  const theme = useTheme();

  return (
    <StyledScreenContainer
      $customStyle={{
        minHeight: '560px',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        background: theme.color.lpColors.openingScreen,
      }}
      id={data?.sectionId}
    >
      <ImageBox>
        <StyledImage fetchPriority="high" src={GeneralImages.background1} alt="logo" draggable="false" />
      </ImageBox>
      <ScreenContent>
        {!taxesAndConditionsData ? (
          <TextContainer>
            <TextProcessing text={data?.title} customStyle={styledTitle(TextVariants.PRIMARY, theme)} />
            <StyledText>
              <TextProcessing text={data?.text} customStyle={styledText(TextVariants.PRIMARY, theme, true)} />
            </StyledText>
          </TextContainer>
        ) : (
          <TablesContainer>
            <CardBlack>
              <BlackTitle>
                <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.provenIncome'} />
              </BlackTitle>
              <InnerTable>
                {taxesAndConditionsData?.provenIncome.map((item, idx) => (
                  <InnerCard className="innerCard" key={`innercard-${idx}`}>
                    <span>
                      <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.from'} />
                    </span>
                    <BigNumber>
                      <span>
                        <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.realSymbol'} />
                      </span>
                      {FormatUtils.formatDecimal(item.from)}
                    </BigNumber>
                    <span>
                      <Trans
                        i18nKey={'landingPage.TaxesAndConditionsScreen.fees'}
                        values={{ fees: FormatUtils.formatPercent(item.fees) }}
                      />
                    </span>
                  </InnerCard>
                ))}
              </InnerTable>
            </CardBlack>
            <CardRed>
              <RedTitle>
                <span>
                  <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.guarantee'} />
                </span>
                <p>
                  <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.alienate'} />
                </p>
                <ShadowElipse2 />
              </RedTitle>
              <InnerTable>
                {taxesAndConditionsData?.guarantee.map((item, idx) => (
                  <InnerCard className="innerCard" key={`innercard2-${idx}`}>
                    <span>
                      <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.from'} />
                    </span>
                    <BigNumber>
                      <span>
                        <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.realSymbol'} />
                      </span>
                      {FormatUtils.formatDecimal(item.from)}
                    </BigNumber>
                    <span>
                      <Trans
                        i18nKey={'landingPage.TaxesAndConditionsScreen.fees'}
                        values={{ fees: FormatUtils.formatPercent(item.fees) }}
                      />
                    </span>
                  </InnerCard>
                ))}
              </InnerTable>
            </CardRed>
            <SpecialSpan>
              <a href="https://api.whatsapp.com/send?phone=61996826925" target={'_blank'}>
                <Trans i18nKey={'landingPage.TaxesAndConditionsScreen.contact'} values={{ via: 'Whatsapp' }} />
                <WhatsIconRed />
              </a>
            </SpecialSpan>
          </TablesContainer>
        )}
        <Simulator />
      </ScreenContent>
    </StyledScreenContainer>
  );
};

export default Desktop;
