import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

interface Props {
  $customStyle?: any;
}

export const ScreenContent = styled.div`
  max-width: 1440px;
  width: 100%;
  min-width: 480px;

  /* padding: 48px 128px 80px; */

  z-index: 10;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 36px 64px 68px;
  }

  @media (max-width: ${ScreenDimensions.TABLET}) {
    padding: 24px 52px 56px;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    max-width: 480px;
    width: 100%;
    min-width: 280px;

    padding: 24px 16px 44px;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    padding: 32px 16px 44px 16px;
    min-width: unset;
  }
`;

export const TextContainer = styled.div`
  max-width: 366px;
  display: flex;
  flex-direction: column;
  margin: 48px 128px 80px;
  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    max-width: 480px;
    margin: auto;
    margin-bottom: 24px;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    max-width: 480px;
    width: 100%;
    min-width: 280px;
    margin-bottom: 24px;
  }
`;

export const StyledText = styled.div`
  text-align: left;

  margin: 24px 0px 0px;
`;

export const Pagination = styled.div`
  width: 1440px;
  height: 12px;

  margin: 48px 0px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1055px) {
    width: 672px;
  }
  @media (max-width: 671px) {
    width: 320px;
  }
`;

export const PaginationItem = styled.button<Props>`
  width: ${({ $customStyle, theme }) => ($customStyle.isActive ? '10px' : '8px')};
  height: ${({ $customStyle, theme }) => ($customStyle.isActive ? '10px' : '8px')};

  background: ${({ $customStyle, theme }) => ($customStyle.isActive ? theme.color.primaryColor : '#eff6ff')};
  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};
  border-radius: 100%;

  padding: 0px;

  margin: 0px 12px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledImage = styled.img`
  max-height: auto;
  user-select: none;
  height: 100%;
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: 0vw;
  z-index: 1;
  max-width: 54%;
  @media (min-width: 1500px) {
    right: unset;
    transform: translateX(38%);
  }

  /* ele esta tirando a imagem de teblets*/
  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    display: none;
  }
`;

export const StyledImgnContainer = styled.div<Props>`
  width: 100%;
  ${({ $customStyle }) => $customStyle.minHeight && `min-height: ${$customStyle.minHeight}`};
  ${({ $customStyle }) => $customStyle.padding && `padding: ${$customStyle.padding};`}

  ${({ $customStyle }) => {
    if ($customStyle.backgroundImage) {
      return `
      background-image: url(${$customStyle.backgroundImage});
      background-position: center center;
      background-size: cover;
      transition: all 1s ease;
      `;
    } else {
      return `  background: ${$customStyle.background ? $customStyle.background : '#FFFFFF'};
      `;
    }
  }} 
  ${({ $customStyle }) => $customStyle.backgroundPosition && `background-position: ${$customStyle.backgroundPosition}`};
  ${({ $customStyle }) => $customStyle.backgroundSize && `background-size: ${$customStyle.backgroundSize}`};

  background-repeat: no-repeat;
  display: flex;
  flex-direction: ${({ $customStyle }) => ($customStyle.flexDirection ? $customStyle.flexDirection : 'row')};
  align-items: ${({ $customStyle }) => ($customStyle.alignItems ? $customStyle.alignItems : 'flex-start')};
  justify-content: ${({ $customStyle }) => ($customStyle.justifyContent ? $customStyle.justifyContent : 'flex-start')};
  height: 518px;
  position: absolute;
  overflow-x: hidden;
`;
