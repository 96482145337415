import facebook from './facebook.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import twitter from './twitter.svg';
import youtube from './youtube.svg';

export const FooterImgs = {
  facebook,
  instagram,
  twitter,
  linkedin,
  youtube,
};
