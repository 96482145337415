import TextProcessing from 'components/text-processing';
import { styledText } from 'components/text-processing/style-constants';
import { FaqScreenImg } from 'images/faq-screen';
import { TextVariants } from 'model/enums/text-variants';
import { useTheme } from 'styled-components';
import { OptionDescription, OptionTitle, StyledImg, StyledOption, StyledQuestionTitle } from './styled';

interface OptionType {
  title: string;
  text: string;
}

interface OptionProps {
  data: OptionType;
  position: number;
  currentPosition: number;
  setCurrentPosition: (position: number) => void;
}

const Option: React.FC<OptionProps> = ({ data, position, currentPosition, setCurrentPosition }) => {
  const isActive = position === currentPosition;
  const theme = useTheme();

  const verifyOption = position => {
    setCurrentPosition(position);
    if (isActive) {
      setCurrentPosition(0);
    }
  };

  return (
    <StyledOption onClick={() => verifyOption(position)} $customStyle={{ isActive: isActive }}>
      <OptionTitle>
        <StyledQuestionTitle>{data?.title}</StyledQuestionTitle>
        <StyledImg>
          {isActive ? <FaqScreenImg.chevronIcon style={{ transform: 'rotate(180deg)' }} /> : <FaqScreenImg.chevronIcon />}
        </StyledImg>
      </OptionTitle>

      {isActive && (
        <OptionDescription>
          <TextProcessing text={data?.text} customStyle={styledText(TextVariants.PRIMARY, theme)} />
        </OptionDescription>
      )}
    </StyledOption>
  );
};

export default Option;
