import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { InfinityDataScreenType } from 'model/landing-page';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface InfinityDataScreenProps {}

const InfinityDataScreen: React.FC<InfinityDataScreenProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);

  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const data: InfinityDataScreenType | undefined = landingPageTemplate?.infinityDataScreen;

  return !isMobile ? <Desktop data={data} /> : <Mobile data={data} />;
};

export default InfinityDataScreen;
