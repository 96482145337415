import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { FooterScreenType } from 'model/landing-page';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { isMidTablet } = useContext(UseResponsiveContext);

  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const data: FooterScreenType | undefined = landingPageTemplate?.footerScreen;

  return !isMidTablet ? <Desktop data={data} /> : <Mobile data={data} />;
};

export default Footer;
