import { SelectListsProvider } from 'provider/select-list';
import FaqScreen from './faq-screen';
import InfinityDataScreen from './infinity-data-screen';
import OpeningScreen from './opening-screen';
import StepByStepScreen from './step-by-step-screen';
import WhatsIsScreen from './what-is-screen';
import TaxesAndConditionsScreen from './taxes-and-conditions-screen';
import { useContext } from 'react';
import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';

interface LandingPageContainerProps {}

const LandingPageContainer: React.FC<LandingPageContainerProps> = () => {
  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const { isMobile } = useContext(UseResponsiveContext);

  return (
    <>
      <SelectListsProvider>
        <OpeningScreen />
      </SelectListsProvider>
      <InfinityDataScreen />
      <WhatsIsScreen />
      <StepByStepScreen />
      <FaqScreen />
    </>
  );
};

export default LandingPageContainer;
