export default {
  primaryColor: '#FF6701',
  primaryColorDarker: '#FF6701',
  primaryColorLight: '#ffc59e',
  customColorText: '#FF6701',
  titleText: '#07203C', //
  paragraphText: '#656666', //
  lpColors: {
    headerLp: '#FFFFFF',
    headerTextColor: '#FF6701',
    openingScreen: '#FFFFFF',
    infinityDataScreen: '#4158A1',
    infinityDataText: '#FFFFFF', //
    infinityDataCircle: '#656666', //
    infinityDataIcon: '#FFFFFF', //
    whatIsScreen: '#FFFFFF',
    whatIsScreenTextColor: '#fff',
    whatIsIcon: '#E2162E',
    stepColor: '#07203C',
    stepByStepScreen: '#FFFFFF',
    faqScreen: '#308CB0',
    footerLp: '#343A3F',
    simulatorButton: '#FF6701',
    color1: '#FF6701', //green1
    color2: '#308CB0', //green2
    color3: '#07203C', //blue1
    color4: '#1A2E4E', //blue2
    color5: '#2B4E85', //blue3
    color6: '#F3F8FF', //light gray
  },
};
