import { InfinityDataScreenType } from 'model/landing-page';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import InfoList from '../infoList';

interface DesktopProps {
  data?: InfinityDataScreenType;
}

const Desktop: React.FC<DesktopProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <StyledScreenContainer
      $customStyle={{
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.color.lpColors.infinityDataScreen,
      }}
      id={data?.sectionId}
    >
      <InfoList data={data} />
    </StyledScreenContainer>
  );
};

export default Desktop;
