import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'components/text-processing/style-constants';
import { TextVariants } from 'model/enums/text-variants';
import { ItensType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { ImageBox, PositionStyled, StepCard, StepCount, StepText, StepTitle, StyledImage, TextBox } from './styled';

interface StepProps {
  item: ItensType;
  position: string;
  isReverse: boolean;
  cardImage?: string;
}

const Step: React.FC<StepProps> = ({ item, position, isReverse, cardImage }) => {
  const theme = useTheme();
  return (
    <StepCard>
      <PositionStyled>{position}</PositionStyled>
      <TextBox>
        <StepTitle>{item?.title}</StepTitle>
        <StepText>{item?.text}</StepText>
      </TextBox>
    </StepCard>
  );
};

export default Step;
