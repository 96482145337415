import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { stepByStepType } from 'model/landing-page';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface StepByStepScreenProps {}

const StepByStepScreen: React.FC<StepByStepScreenProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);

  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const data: stepByStepType | undefined = landingPageTemplate?.stepByStep;
  return !isMobile ? <Desktop data={data} /> : <Mobile data={data} />;
};

export default StepByStepScreen;
