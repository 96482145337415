import { TextVariants } from 'model/enums/text-variants';

export const styledTitle = (variant: string, theme, changeColor?: boolean) => {
  const primaryColor = theme.color.primaryColor;
  const primaryColorDark = theme.color.primaryColorDarker;
  const white = theme.color.white;
  const black = theme.color.black;
  const customText = theme?.color?.customColorText;

  const customColor = theme.color.lpColors.whatIsScreenTextColor;

  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          color: black,
          fontWeight: '700',
          fontSize: '40px',
          lineHeight: '44px',
        },
        mobile: {
          color: black,
          fontWeight: '700',
          fontSize: '28px',
          lineHeight: '34px',
        },
        custom: {},
      };
    case TextVariants.SECONDARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '32px !important',
          lineHeight: '38px',
          color: changeColor ? customColor : white,
        },
        mobile: {
          fontSize: '24px',
        },
      };
    case TextVariants.TERTIARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '32px',
          lineHeight: '48px',
          color: black,
        },
        mobile: {
          fontSize: '24px',
        },
      };
    case TextVariants.QUATERNARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '20px',
          lineHeight: '30px',
          color: black,
        },
        mobile: {},
      };
    case TextVariants.QUINARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '24px',
          lineHeight: '36px',
          color: white,
        },
        mobile: {},
      };

    default:
      return {
        default: {
          color: primaryColor,
          fontWeight: '700',
          fontSize: '40px',
          lineHeight: '44px',
        },
        mobile: {},
        custom: {
          color: primaryColorDark,
        },
      };
  }
};

export const styledSubtitle = (variant: string, theme) => {
  const primaryColor = theme.color.primaryColor;
  const primaryColorDark = theme.color.primaryColorDarker;
  const white = theme.color.white;
  const primaryColorLight = theme.color.primaryColorLight;
  const black = theme.color.black;

  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '36px',
          color: primaryColor,
        },
        mobile: {},
      };
    default:
      return {
        default: {
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '36px',
          color: theme.color.gray18,
        },
        mobile: {},
      };
  }
};

export const styledText = (variant: string, theme, changeColor?: boolean, custom?: boolean) => {
  const primaryColor = theme.color.primaryColor;
  const white = theme.color.white;
  const customText = theme?.color?.customColorText;
  const darkGray = theme.color.gray22;
  const black = theme.color.black;
  const customColor = theme.color.lpColors.whatIsScreenTextColor;

  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: darkGray,
        },
        mobile: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          color: darkGray,
        },
        custom: {
          fontWeight: '700',
        },
      };
    case TextVariants.SECONDARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '24px',
          color: white,
        },
        mobile: {
          fontSize: '14px',
          lineHeight: '16px',
        },
      };
    case TextVariants.TERTIARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: changeColor ? customColor : white,
        },
        mobile: {},
        custom: {
          fontWeight: '700',
        },
      };
    case TextVariants.QUATERNARY:
      return {
        default: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '21px',
          color: white,
        },
      };
    case TextVariants.QUINARY:
      return {
        default: {
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: darkGray,
        },
        mobile: {},
      };
    case TextVariants.SENARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#FFFFFF',
        },
      };
    case TextVariants.SEPTENARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '21px',
          color: '#525252',
        },
      };
    default:
      return {
        default: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#fff',
        },
        mobile: {},
      };
  }
};
