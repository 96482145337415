import { ReactComponent as calendar } from '../general-images/calendar.svg';
import { ReactComponent as dollar } from '../general-images/dollar.svg';
import { ReactComponent as lightning } from '../general-images/lightning2.svg';
import { ReactComponent as clock } from '../general-images/clock2.svg';

export const InfinityScreenImage = {
  calendar,
  dollar,
  clock,
  lightning,
};
