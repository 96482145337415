import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { WhatIsScreenType } from 'model/landing-page';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface WhatsIsScreenProps {}

const WhatsIsScreen: React.FC<WhatsIsScreenProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);
  const { landingPageTemplate } = useContext(LandingPageTemplateContext);

  const data: WhatIsScreenType | undefined = landingPageTemplate?.whatIsScreen;

  return !isMobile ? <Desktop data={data} /> : <Mobile data={data} />;
};

export default WhatsIsScreen;
