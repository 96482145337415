import { CampName } from 'features/admin/clientData/components/RegisterDataDisplay/campDisplay/styles';
import { Status } from 'features/admin/dashboard/components/status';
import { useTranslation } from 'react-i18next';
import {
  ContentContainer,
  Container,
  GrayInfo,
  TitleContainer,
  ScoreBarContainer,
  ColoredBar,
  YellowColoredBar,
  LightRedColoredBar,
  RedColoredBar,
  LightGreenColoredBar,
  GreenColoredBar,
  PointerContainer,
  DescritionColorBar,
  CenterDescritionColorBar,
  StatusContainer,
  InfoButton,
  InfoModalContainer,
  DescritionModal,
  RedVertivalBar,
  ColorContainer,
  YellowVertivalBar,
  GreenVertivalBar,
  DescriptionContainer,
} from './styles';
import { ReactComponent as ScorePointer } from 'images/score-pointer.svg';
import { ReactComponent as InfoIconSvg } from 'images/info.svg';
import { useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import CustomButton from 'components/custom-button';
import { UnicoProcess } from 'model/signatures';
import { RegistrationStatus } from 'model/enums/registration-status';

interface IProps {
  unicoData?: UnicoProcess;
}

export const TabUnico = ({ unicoData }: IProps) => {
  const { t } = useTranslation();
  const [deslocate, setDeslocate] = useState('50%');
  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);

  const deslocateBar = () => {
    if (unicoData?.score) {
      if (unicoData.score === 0) {
        setDeslocate('50%');
      } else if (unicoData.score > 0) {
        let deslocateNumber = Math.round(((unicoData.score - 1) * (46 - 1)) / (100 - 2) + 1) + 53.5;
        setDeslocate(`${deslocateNumber}%`);
      } else if (unicoData.score < 0) {
        let deslocateNumber = 46.5 - Math.round(((Math.abs(unicoData.score) - 1) * (46 - 1)) / (100 - 2) + 1);
        setDeslocate(`${deslocateNumber}%`);
      }
    }
  };

  useEffect(() => {
    deslocateBar();
  }, [unicoData?.score]);

  return (
    <>
      <TitleContainer>{t('admin.signatures.tabUnico.uniqueId')}</TitleContainer>
      <Container>
        <div>
          <label>{t('register.inputs.id')}</label>
          <GrayInfo>{unicoData?.idUnico ?? '000'}</GrayInfo>
        </div>
        <ContentContainer>
          <CampName style={{ width: 'fit-content' }}>{t('admin.signatures.statusAnalysisUnico')}</CampName>
          <>
            <Status
              big
              text={
                unicoData?.statusProcess === 'FRAUD_NEGATIVE'
                  ? t(`enum.unicoProcessStatus.approvedReal`)
                  : unicoData?.statusProcess
                    ? t(`enum.unicoProcessStatus.${unicoData?.statusProcess}`)
                    : ''
              }
              color={unicoData?.statusProcess.toString() ?? ''}
            />
          </>
        </ContentContainer>
      </Container>
      {unicoData?.score !== undefined ? (
        <>
          <TitleContainer>{t('admin.signatures.tabUnico.score')}</TitleContainer>

          <ScoreBarContainer>
            <PointerContainer
              style={{
                left: deslocate,
              }}
            >
              {unicoData?.score}
              <ScorePointer />
            </PointerContainer>
            <ColoredBar>
              <RedColoredBar />
              <LightRedColoredBar />
              <YellowColoredBar />
              <LightGreenColoredBar />
              <GreenColoredBar />
            </ColoredBar>
            <div></div>
            <ColoredBar>
              <DescritionColorBar>
                <b>-100 a -40</b>
                <b>{t('admin.signatures.tabUnico.negative')}</b>
                {t('admin.signatures.tabUnico.evidenceFraud')}
              </DescritionColorBar>
              <DescritionColorBar>
                <b>-1 a -39</b>
                <b>{t('admin.signatures.tabUnico.negative')}</b>
              </DescritionColorBar>
              <CenterDescritionColorBar>
                <b>0</b>
                <b>
                  Neutro
                  {t('admin.signatures.tabUnico.neutral')}
                </b>
              </CenterDescritionColorBar>
              <DescritionColorBar>
                <b>+1 a +49</b>
                <b>{t('admin.signatures.tabUnico.positive')}</b>
              </DescritionColorBar>
              <DescritionColorBar>
                <b>+50 e +100</b>
                <b>{t('admin.signatures.tabUnico.positive')}</b>
                {t('admin.signatures.tabUnico.evidenceGenuine')}
              </DescritionColorBar>
            </ColoredBar>
          </ScoreBarContainer>
        </>
      ) : (
        <TitleContainer>{t('admin.signatures.tabUnico.scoreNotGenerated')}</TitleContainer>
      )}
      <InfoButton
        onClick={() => {
          setShowComponentModal(true);
        }}
      >
        <InfoIconSvg />
        {t('admin.signatures.tabUnico.scoreInfo')}
      </InfoButton>
      <StatusContainer>
        <ContentContainer>
          <CampName style={{ width: 'fit-content' }}>{t('enum.unico.proofOfLife')}</CampName>
          <>
            <Status
              big
              text={
                unicoData?.processStatus?.statusBiometricUnico === 1
                  ? t(`enum.numberStatus.approved`)
                  : unicoData?.processStatus?.statusBiometricUnico === 2
                    ? t(`enum.numberStatus.photoOfPhoto`)
                    : t(`enum.unicoProcessStatus.OFF`)
              }
              color={
                unicoData?.processStatus?.statusBiometricUnico === 3 || unicoData?.processStatus?.statusBiometricUnico === 1
                  ? RegistrationStatus.APPROVED
                  : unicoData?.processStatus?.statusBiometricUnico === 4 || unicoData?.processStatus?.statusBiometricUnico === 2
                    ? RegistrationStatus.REJECTED
                    : RegistrationStatus.IN_PROGRESS
              }
            />
          </>
        </ContentContainer>
        <ContentContainer>
          <CampName style={{ width: 'fit-content' }}>{t('enum.unico.statusOCRInternal')}</CampName>
          <>
            <Status
              big
              text={
                unicoData?.processStatus.statusOCRUnico === 1
                  ? t(`enum.numberStatus.approved`)
                  : unicoData?.processStatus?.statusOCRUnico === 2
                    ? t(`enum.numberStatus.repproved`)
                    : t(`enum.unicoProcessStatus.OFF`)
              }
              color={
                unicoData?.processStatus?.statusOCRUnico === 3 || unicoData?.processStatus?.statusOCRUnico === 1
                  ? RegistrationStatus.APPROVED
                  : unicoData?.processStatus?.statusOCRUnico === 4 || unicoData?.processStatus?.statusOCRUnico === 2
                    ? RegistrationStatus.REJECTED
                    : RegistrationStatus.IN_PROGRESS
              }
            />
          </>
        </ContentContainer>
        <ContentContainer>
          <CampName style={{ width: 'fit-content' }}>{t('enum.unico.statusFaceMatchInternal')}</CampName>
          <>
            <Status
              big
              text={
                unicoData?.processStatus.statusFaceMatchUnico === 1
                  ? t(`enum.numberStatus.approved`)
                  : unicoData?.processStatus?.statusFaceMatchUnico === 2
                    ? t(`enum.numberStatus.repproved`)
                    : t(`enum.unicoProcessStatus.OFF`)
              }
              color={
                unicoData?.processStatus?.statusFaceMatchUnico === 3 || unicoData?.processStatus?.statusFaceMatchUnico === 1
                  ? RegistrationStatus.APPROVED
                  : unicoData?.processStatus?.statusFaceMatchUnico === 4 || unicoData?.processStatus?.statusFaceMatchUnico === 2
                    ? RegistrationStatus.REJECTED
                    : RegistrationStatus.IN_PROGRESS
              }
            />
          </>
        </ContentContainer>
      </StatusContainer>

      {showComponentModal && (
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <InfoModalContainer>
            <ColorContainer>
              <RedVertivalBar />
              <DescriptionContainer>
                <DescritionModal>
                  <b>{t('admin.signatures.tabUnico.between')}-40 e -100</b>
                  {t('admin.signatures.tabUnico.conclusionFraud')}
                </DescritionModal>
                <DescritionModal>
                  <b>{t('admin.signatures.tabUnico.between')}-1 a -39</b>
                  {t('admin.signatures.tabUnico.lowEvidenceFraud')}
                </DescritionModal>
              </DescriptionContainer>
            </ColorContainer>
            <ColorContainer>
              <YellowVertivalBar />
              <DescritionModal>
                <b>{t('admin.signatures.tabUnico.scoreZero')}</b>
                {t('admin.signatures.tabUnico.inconclusive')}
              </DescritionModal>
            </ColorContainer>
            <ColorContainer>
              <GreenVertivalBar />
              <DescriptionContainer>
                <DescritionModal>
                  <b>{t('admin.signatures.tabUnico.between')}+1 a 49</b>
                  {t('admin.signatures.tabUnico.lowEvidenceGenuine')}
                </DescritionModal>
                <DescritionModal>
                  <b>{t('admin.signatures.tabUnico.between')}+50 e +100</b>
                  {t('admin.signatures.tabUnico.sufficientEvidenceGenuine')}
                </DescritionModal>
              </DescriptionContainer>
            </ColorContainer>
            <CustomButton onClick={() => setShowComponentModal(false)}>{t('admin.signatures.tabUnico.goBack')}</CustomButton>
          </InfoModalContainer>
        </CustomComponentModal>
      )}
    </>
  );
};
