import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { OpeningScreenType, TaxesAndConditionsScreenType } from 'model/landing-page';
import { useContext } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';
import { SelectListsProvider } from 'provider/select-list';

interface OpeningScreenProps {}

const OpeningScreen: React.FC<OpeningScreenProps> = () => {
  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const { isMobile } = useContext(UseResponsiveContext);

  const data: OpeningScreenType | undefined = landingPageTemplate?.openingScreen;
  const taxesAndConditionsData: TaxesAndConditionsScreenType | undefined = landingPageTemplate?.taxesAndConditionsScreen;

  return (
    <SelectListsProvider>
      {!isMobile ? (
        <Desktop data={data} taxesAndConditionsData={taxesAndConditionsData} />
      ) : (
        <Mobile data={data} taxesAndConditionsData={taxesAndConditionsData} />
      )}
    </SelectListsProvider>
  );
};

export default OpeningScreen;
