import background2 from '../custom-organization/background2.png';
import background2mobile from '../custom-organization/background2mobile.png';
import { ReactComponent as clock } from '../general-images/clock.svg';
import { ReactComponent as gift } from '../general-images/gift.svg';
import { ReactComponent as lightning } from '../general-images/lightning.svg';
import { ReactComponent as percentage } from '../general-images/percentage.svg';
import { ReactComponent as xCircle } from '../general-images/xCircle.svg';
import { ReactComponent as circlePercentage } from '../general-images/circlePercentage.svg';
import { ReactComponent as circleMobilePhone } from '../general-images/circleMobilePhone.svg';
import { ReactComponent as circleShield } from '../general-images/circleShield.svg';
import shadowElipse2 from './shadowElipse2.png';

export const WhatsIsImage = {
  shadowElipse2,
  background2,
  background2mobile,
  lightning,
  percentage,
  clock,
  xCircle,
  gift,
  circlePercentage,
  circleMobilePhone,
  circleShield,
};
