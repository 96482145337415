import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { FaqScreenType } from 'model/landing-page';
import { useContext, useState } from 'react';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface FaqScreenProps {}

const FaqScreen: React.FC<FaqScreenProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);
  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const data: FaqScreenType | undefined = landingPageTemplate?.faqScreen;

  const [currentPosition, setCurrentPosition] = useState<number>(0);

  return !isMobile ? (
    <Desktop data={data} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
  ) : (
    <Mobile data={data} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
  );
};

export default FaqScreen;
