import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'components/text-processing/style-constants';
import { StepByStepImage } from 'images/step-by-step';
import { TextVariants } from 'model/enums/text-variants';
import { stepByStepType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import Step from '../step';
import { ScreenContent, StepBox, StyledText, StyledTitle } from '../style';
import { StyledGoToSimulatorButton } from 'components/landing-page/what-is-screen/style';
import { useTranslation } from 'react-i18next';

interface DesktopProps {
  data?: stepByStepType;
}

const Desktop: React.FC<DesktopProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledScreenContainer
      $customStyle={{
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: theme.color.lpColors.stepByStepScreen,
      }}
      id={data?.sectionId}
    >
      <ScreenContent>
        <StyledTitle>
          <TextProcessing text={data?.title} customStyle={styledTitle(TextVariants.TERTIARY, theme)} />
        </StyledTitle>
        <StyledText>
          <TextProcessing text={data?.text} customStyle={styledText(TextVariants.PRIMARY, theme)} />
        </StyledText>

        <StepBox>
          {data?.steps.map((item, idx) => (
            <Step key={idx} item={item} cardImage={StepByStepImage[idx]} position={`0${idx + 1}`} isReverse={idx % 2 !== 0} />
          ))}
        </StepBox>
        <StyledGoToSimulatorButton
          style={{
            maxWidth: '200px',
            margin: '32px auto 0',
          }}
          onClick={scrollToTop}
        >
          {`${t('simulatorData.title')}`}
        </StyledGoToSimulatorButton>
      </ScreenContent>
    </StyledScreenContainer>
  );
};

export default Desktop;
