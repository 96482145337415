import styled from 'styled-components';

interface Props {
  customStyle?: any;
}

export const FaqContainer = styled.div`
  width: 672px;

  display: flex;
  flex-direction: column;
  padding-top: 72px;
  padding-bottom: 60px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
  }

  @media (max-width: 671px) {
    width: 100%;
    margin: 40px 18px;
  }
`;

export const StyledTitle = styled.div<Props>`
  margin-bottom: 24px;

  display: flex;
  justify-content: center;
  color: var(--gray-dark-1, #1b1a1b);
  font-family: Satoshi;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const OptionList = styled.div`
  height: auto;
  min-height: 264px;
  margin: 0px;

  max-width: 673px;
`;
